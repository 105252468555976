<template>
  <div>
    <div v-if="loading"><b-spinner></b-spinner> carregando...</div>
    <div v-if="!loading && ingredientes && ingredientes.length > 0">
      <div class="text-right py-2">
        <b-input
          v-model="filtro"
          class="float-right mb-4"
          style="max-width: 200px"
          placeholder="filtrar..."
        ></b-input>
      </div>
      <b-table
        :items="ingredientesFiltred"
        :fields="[
          { key: 'ingrediente', label: 'Ingrediente', sortable: true },
          {
            key: 'totalGasto',
            label: 'Qtd. Consumido',
            class: 'text-center',
            sortable: true,
          },

          {
            key: 'totalPedidos',
            label: 'Pedidos',
            class: 'text-center',
            sortable: true,
          },
        ]"
        sorteable
        hover
        selectable
        select-mode="single"
        @row-clicked="rowClick"
      >
        <template #cell(totalGasto)="row">
          {{ row.item.totalGasto | currency }} kg
        </template>
        <template #cell(totalPedidos)="row">
          {{ row.item.totalPedidos }}
        </template>
      </b-table>
    </div>
    <div v-if="!loading && (!ingredientes || ingredientes.length <= 0)">
      <h2 class="text-center">Nenhum ingrediente encontrado :(</h2>
    </div>
    <b-modal
      id="modal-consumo"
      :title="`Consumo de ${
        ingredienteSelected ? ingredienteSelected.ingrediente : ''
      }`"
      size="xl"
      hide-footer
    >
      <div class="row mb-5" v-if="ingredienteSelected">
        <div class="col-12 col-md-4">
          <b-card class="text-center">
            <b-card-text>
              <h6>Total Consumido</h6>
              <small>
                Periodo {{ this.dateRange.start | moment("DD/MM/YYYY") }}
                até
                {{ this.dateRange.end | moment("DD/MM/YYYY") }}
              </small>
              <h3>
                {{ ingredienteSelected.totalGasto | currency }}
                <small>kg</small>
              </h3>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-12 col-md-4">
          <b-card class="text-center">
            <b-card-text>
              <h6>Total de Pedidos</h6>
              <small>
                Periodo {{ this.dateRange.start | moment("DD/MM/YYYY") }}
                até
                {{ this.dateRange.end | moment("DD/MM/YYYY") }}
              </small>
              <h3>
                {{ ingredienteSelected.totalPedidos }}
              </h3>
            </b-card-text>
          </b-card>
        </div>
        <div class="col-12 col-md-4">
          <b-card class="text-center">
            <b-card-text>
              <h6>Estoque Atual</h6>
              <br />
              <h3>
                {{ ingredienteSelected.qtdGasto | currency }}
                <small>kg</small>
              </h3>
            </b-card-text>
          </b-card>
        </div>
      </div>
      <div class="row mb-4">
        <div class="col-12 col-md-4">
          <b>Agrupamento</b>
          <b-select
            v-model="pedidoAgrupamento"
            :options="[
              'Produto',
              'Produto/Tamanho',
              'Produto/Tamanho/Montagem',
            ]"
          ></b-select>
        </div>
        <div class="col-12 col-md-4">
          <b>Buscar Produto</b>
          <b-input v-model="pedidoFiltro" placeholder="filtrar..."></b-input>
        </div>
      </div>
      <b-table
        style="font-size: 10px"
        small
        hover
        :items="pedidos ? pedidos : []"
        :fields="[
          { key: 'nome', label: 'Produto', sortable: true },
          {
            key: 'tipo',
            label: 'Tamanho',
            sortable: true,
            class: this.pedidoAgrupamento.includes('Tamanho') ? '' : 'd-none',
          },
          {
            key: 'tamanho',
            label: 'Montagem',
            sortable: true,
            class: this.pedidoAgrupamento.includes('Montagem') ? '' : 'd-none',
          },
          {
            key: 'consumoMedio',
            label: 'Consumo Médio',
            class:
              'text-center ' +
              (this.pedidoAgrupamento.includes('Montagem') ? '' : 'd-none'),
            sortable: true,
          },
          {
            key: 'qtd',
            label: 'Qtd. Pedidos',
            class: 'text-center',
            sortable: true,
          },
          {
            key: 'qtdGasto',
            label: 'Qtd. Consumida',
            class: 'text-center',
            sortable: true,
          },
        ]"
      >
        <template #cell(consumoMedio)="row">
          {{ row.item.consumoMedio | currency }} kg
        </template>
        <template #cell(qtdGasto)="row">
          {{ row.item.qtdGasto | currency }} kg
        </template>
      </b-table>

      <div class="m-3 text-right">
        <hr />
        <b-btn @click="$bvModal.hide('modal-consumo')">Fechar</b-btn>
      </div>
    </b-modal>
  </div>
</template>

<script>
import IngredientesLib from "../../../libs/IngredientesLib";

export default {
  components: {},
  props: {
    empresaSelecionada: Array,
    dateRange: Object,
  },
  mounted() {
    this.carregarIngredientes();
  },
  data() {
    return {
      ingredientes: [],
      loading: false,
      filtro: "",
      ingredienteSelected: null,
      pedidoAgrupamento: "Produto",
      pedidoFiltro: "",
    };
  },
  watch: {
    dateRange: {
      deep: true,
      handler() {
        this.carregarIngredientes();
      },
    },
    empresaSelecionada: {
      handler() {
        this.carregarIngredientes();
      },
    },
  },
  computed: {
    ingredientesFiltred() {
      return this.ingredientes.filter(
        (x) =>
          !this.filtro ||
          this.filtro.length <= 0 ||
          x.ingrediente.toLowerCase().includes(this.filtro.toLowerCase())
      );
    },
    pedidos() {
      return !this.ingredienteSelected
        ? []
        : this.ingredienteSelected.pedidos
            .filter(
              (x) =>
                !this.pedidoFiltro ||
                this.pedidoFiltro.length <= 0 ||
                x.nome.toLowerCase().includes(this.pedidoFiltro.toLowerCase())
            )
            .reduce((ret, vl) => {
              let has = ret.find(
                (x) =>
                  x.nome == vl.nome &&
                  (!this.pedidoAgrupamento.includes("Tamanho") ||
                    (this.pedidoAgrupamento.includes("Tamanho") &&
                      x.tipo == vl.tipo)) &&
                  (!this.pedidoAgrupamento.includes("Montagem") ||
                    (this.pedidoAgrupamento.includes("Montagem") &&
                      x.tamanho == vl.tamanho))
              );
              if (!has) {
                ret.push({
                  nome: vl.nome,
                  tipo: this.pedidoAgrupamento.includes("Tamanho")
                    ? vl.tipo
                    : "TODOS",
                  tamanho: this.pedidoAgrupamento.includes("Montagem")
                    ? vl.tamanho
                    : "TODOS",
                  qtd: 1,
                  qtdGasto: vl.qtdGasto,
                  consumoMedio: vl.consumoMedio,
                  itens: [vl],
                });
              } else {
                has.qtd++;
                has.qtdGasto += vl.qtdGasto;
                has.itens.push(vl);
              }
              return ret;
            }, [])
            .sort((a, b) => (a.tipo > b.tipo ? 1 : -1))
            .sort((a, b) => (a.nome > b.nome ? 1 : -1));
    },
  },
  methods: {
    async carregarIngredientes() {
      this.loading = true;
      this.ingredientes =
        (
          await IngredientesLib.relatorioDeGasto({
            dataInicio: this.dateRange.start,
            dataFim: this.dateRange.end,
            codEmpresa: this.empresaSelecionada.map((e) => e.cod_empresa),
          })
        )?.ingredientes || [];

      console.log("qtd", this.ingredientes);
      this.loading = false;
    },
    rowClick(row) {
      console.log("row", row);
      this.ingredienteSelected = row;

      this.$bvModal.show("modal-consumo");
    },
  },
};
</script>

<style lang="scss" scoped></style>
