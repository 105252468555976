var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.loading)?_c('div',[_c('b-spinner'),_vm._v(" carregando...")],1):_vm._e(),(!_vm.loading && _vm.ingredientes && _vm.ingredientes.length > 0)?_c('div',[_c('div',{staticClass:"text-right py-2"},[_c('b-input',{staticClass:"float-right mb-4",staticStyle:{"max-width":"200px"},attrs:{"placeholder":"filtrar..."},model:{value:(_vm.filtro),callback:function ($$v) {_vm.filtro=$$v},expression:"filtro"}})],1),_c('b-table',{attrs:{"items":_vm.ingredientesFiltred,"fields":[
        { key: 'ingrediente', label: 'Ingrediente', sortable: true },
        {
          key: 'totalGasto',
          label: 'Qtd. Consumido',
          class: 'text-center',
          sortable: true,
        },

        {
          key: 'totalPedidos',
          label: 'Pedidos',
          class: 'text-center',
          sortable: true,
        },
      ],"sorteable":"","hover":"","selectable":"","select-mode":"single"},on:{"row-clicked":_vm.rowClick},scopedSlots:_vm._u([{key:"cell(totalGasto)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.totalGasto))+" kg ")]}},{key:"cell(totalPedidos)",fn:function(row){return [_vm._v(" "+_vm._s(row.item.totalPedidos)+" ")]}}],null,false,647729083)})],1):_vm._e(),(!_vm.loading && (!_vm.ingredientes || _vm.ingredientes.length <= 0))?_c('div',[_c('h2',{staticClass:"text-center"},[_vm._v("Nenhum ingrediente encontrado :(")])]):_vm._e(),_c('b-modal',{attrs:{"id":"modal-consumo","title":`Consumo de ${
      _vm.ingredienteSelected ? _vm.ingredienteSelected.ingrediente : ''
    }`,"size":"xl","hide-footer":""}},[(_vm.ingredienteSelected)?_c('div',{staticClass:"row mb-5"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b-card',{staticClass:"text-center"},[_c('b-card-text',[_c('h6',[_vm._v("Total Consumido")]),_c('small',[_vm._v(" Periodo "+_vm._s(_vm._f("moment")(this.dateRange.start,"DD/MM/YYYY"))+" até "+_vm._s(_vm._f("moment")(this.dateRange.end,"DD/MM/YYYY"))+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.ingredienteSelected.totalGasto))+" "),_c('small',[_vm._v("kg")])])])],1)],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('b-card',{staticClass:"text-center"},[_c('b-card-text',[_c('h6',[_vm._v("Total de Pedidos")]),_c('small',[_vm._v(" Periodo "+_vm._s(_vm._f("moment")(this.dateRange.start,"DD/MM/YYYY"))+" até "+_vm._s(_vm._f("moment")(this.dateRange.end,"DD/MM/YYYY"))+" ")]),_c('h3',[_vm._v(" "+_vm._s(_vm.ingredienteSelected.totalPedidos)+" ")])])],1)],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('b-card',{staticClass:"text-center"},[_c('b-card-text',[_c('h6',[_vm._v("Estoque Atual")]),_c('br'),_c('h3',[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.ingredienteSelected.qtdGasto))+" "),_c('small',[_vm._v("kg")])])])],1)],1)]):_vm._e(),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12 col-md-4"},[_c('b',[_vm._v("Agrupamento")]),_c('b-select',{attrs:{"options":[
            'Produto',
            'Produto/Tamanho',
            'Produto/Tamanho/Montagem',
          ]},model:{value:(_vm.pedidoAgrupamento),callback:function ($$v) {_vm.pedidoAgrupamento=$$v},expression:"pedidoAgrupamento"}})],1),_c('div',{staticClass:"col-12 col-md-4"},[_c('b',[_vm._v("Buscar Produto")]),_c('b-input',{attrs:{"placeholder":"filtrar..."},model:{value:(_vm.pedidoFiltro),callback:function ($$v) {_vm.pedidoFiltro=$$v},expression:"pedidoFiltro"}})],1)]),_c('b-table',{staticStyle:{"font-size":"10px"},attrs:{"small":"","hover":"","items":_vm.pedidos ? _vm.pedidos : [],"fields":[
        { key: 'nome', label: 'Produto', sortable: true },
        {
          key: 'tipo',
          label: 'Tamanho',
          sortable: true,
          class: this.pedidoAgrupamento.includes('Tamanho') ? '' : 'd-none',
        },
        {
          key: 'tamanho',
          label: 'Montagem',
          sortable: true,
          class: this.pedidoAgrupamento.includes('Montagem') ? '' : 'd-none',
        },
        {
          key: 'consumoMedio',
          label: 'Consumo Médio',
          class:
            'text-center ' +
            (this.pedidoAgrupamento.includes('Montagem') ? '' : 'd-none'),
          sortable: true,
        },
        {
          key: 'qtd',
          label: 'Qtd. Pedidos',
          class: 'text-center',
          sortable: true,
        },
        {
          key: 'qtdGasto',
          label: 'Qtd. Consumida',
          class: 'text-center',
          sortable: true,
        },
      ]},scopedSlots:_vm._u([{key:"cell(consumoMedio)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.consumoMedio))+" kg ")]}},{key:"cell(qtdGasto)",fn:function(row){return [_vm._v(" "+_vm._s(_vm._f("currency")(row.item.qtdGasto))+" kg ")]}}])}),_c('div',{staticClass:"m-3 text-right"},[_c('hr'),_c('b-btn',{on:{"click":function($event){return _vm.$bvModal.hide('modal-consumo')}}},[_vm._v("Fechar")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }