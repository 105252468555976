import axios from "@/utils/axios";
const get = async (grupo = "") => {
  let result = [];
  await axios
    .get("ingredientes/get", { params: { grupo } })
    .then((data) => {
       //console.log('data', data)
      result = data.data;
      // if (result.data) {
        
      // }
    })
    .catch((err) => {
      console.log("eita, deu erro");
      console.log(err.response);
      throw new Error(err.response.data.message);
    });

  return result;
};

const store = async (data) => {
  // console.log('data recebida no store', data)
  let result;
  await axios
    .post("ingredientes/store", data)
    .then((data) => {
      result = data;
    })
    .catch((err) => {
      result = err;
    });

  return result;
};

const del = async (cod_ingrediente) => {
    try {
      let result = await axios.post('ingredientes/del', { cod_ingrediente })
      return result.data
    } catch (error) {
      console.log("erro ao exc ing", cod_ingrediente);
      return false
    }
    
}

const relatorioDeGasto = async ({
  dataInicio = "",
  dataFim = "",
  codEmpresa = "",
}) => {
    try {
        let result = await axios.get('relatorios/ingredientes', {params: {dataInicio,dataFim,empresa:codEmpresa}})
        return result.data
    } catch (error) {
        console.log('erro ao buscar relatorio de gasto',error.response)
    }
};

export default {
  get,
  store,
  relatorioDeGasto,
    del
};
